@import '../assets/scss/variables.scss';

@keyframes infiniteAnimation {
  0% {
    width: 280px;
    height: 270px;
  }
  50% {
    width: 300px;
    height: 220px;
  }
  100% {
    width: 280px;
    height: 270px;
  }
}
@keyframes fall {
  0% {
    transform: translateY(-140%);
  }

  100% {
    transform: translateY(60%);
  }
}

body {
  padding: 0;
  margin: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  overflow: hidden;
  transition: all 0.3s ease;

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
  }
}

.wrapper_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.loader {
  animation: fall 2s ease infinite, infiniteAnimation 4s ease-in-out infinite;
  animation-direction: alternate;
}
