@import '../../assets/scss/variables.scss';

.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75vh;
  min-height: 719px;
  background-color: $main-white;
  padding: 0 10px;

  @media screen and (max-width: 720px) {
    justify-content: center;
    height: fit-content;
    padding: 0;
    min-height: fit-content;
  }
}

.width {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: $main-white;
  transition: all 0.5s ease;
}

.active_width {
  transform: translateX(20%);
  transition: all 0.5s ease;
}

.menu_vegetables {
  position: relative;
  top: 10%;
  z-index: 9999;

  & img {
    @media screen and (max-width: 1280px) {
      width: 80%;
    }

    @media screen and (max-width: 1080px) {
      width: 100%;
    }

    @media screen and (max-width: 720px) {
      display: none;
    }
  }
}

.menu_info {
  display: flex;
  align-items: center;

  @media screen and (max-width: 720px) {
    flex-direction: column-reverse;
  }

  &__description {
    z-index: 9999;
    @media screen and (max-width: 720px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
  }

  &__text {
    font-family: Montserrat Alternates;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #e64430;
    z-index: 9999;

    @media screen and (max-width: 720px) {
      font-size: 24px;
    }
  }

  &__title {
    font-family: Montserrat Alternates;
    font-weight: 800;
    font-size: 96px;
    line-height: 117px;
    color: #e64430;
    margin: 0;
    text-transform: uppercase;
    transition: all 0.5s ease;
    z-index: 9999;

    @media screen and (max-width: 1280px) {
      font-size: 66px;
    }

    @media screen and (max-width: 720px) {
      font-size: 48px;
      line-height: 48px;
      font-weight: 700;
    }
  }

  &__button {
    display: block;
    background-color: #e64430;
    border: none;
    border-radius: 8px;
    padding: 17px 24px;
    margin-top: 8px;
    width: fit-content;
    color: #fff;
    text-decoration: none;
    font-family: 'SF Pro Display';

    @media screen and (max-width: 720px) {
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }

    @media screen and (max-width: 512px) {
      width: fit-content;
    }
  }

  &__image {
    transition: all 0.5s ease;
    z-index: 112;
    width: 248px;
    height: auto;

    @media screen and (max-width: 720px) {
      width: 200px;
    }
  }
}

.menu_image {
  transform: translateX(-90px);
  position: relative;
  bottom: 0;
  z-index: 9999;

  @media screen and (max-width: 980px) {
    transform: translateX(0);
  }

  @media screen and (max-width: 720px) {
    display: none;
  }
}

.menu_salat {
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    right: 0;
    top: 28%;
  }
}

.menu_tomat {
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15%;
  }
}

.rotate {
  transform: translateX(-90px) rotate(-55deg);
  transition: all 0.5s ease;
}

.less {
  transform: scale(0.7) translateX(-75px);
  transition: all 0.5s ease;
}

.biggest {
  transform: scale(1.2) translateX(20px);
}

.move {
  transform: translateX(120px);
  transition: all 0.5s ease;
}
