@import '../../assets/scss/variables.scss';

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-column: 1 / -1;
  height: 25vh;
  background-color: $main-green;
  z-index: 9999;

  @media screen and (max-width: 980px) {
    min-height: 320px;
    padding-bottom: 20px;
  }
}

.footer_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;

  @media screen and (max-width: 720px) {
    margin-top: 40px;
    padding: 0 10px;
  }

  &__title {
    font-family: Commissioner;
    font-size: 40px;
    line-height: 49px;
    font-weight: 800;
    color: $white-color-text;
    margin: 0;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  &__title_mobile {
    display: none;
    font-family: Montserrat Alternates;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #fff14b;

    @media screen and (max-width: 720px) {
      display: block;
    }
  }

  &__text {
    font-family: Montserrat Alternates;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 8px;

    @media screen and (max-width: 980px) {
      text-align: center;
    }

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  &__text_mobile {
    display: none;
    font-family: Montserrat Alternates;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $main-white;

    @media screen and (max-width: 720px) {
      display: block;
    }
  }
}

.footer_links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 333px;
  margin-top: 16px;

  &__apple,
  &__google {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  & img {
    width: 100%;
  }

  &__apple {
    @media screen and (max-width: 720px) {
      margin-right: 16px;
    }
  }
}

.apple {
  margin-right: 16px;
}

.footer_coppyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 24px);
  margin: 0 12px 8px 12px;

  &__info,
  &__made {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;

    @media screen and (max-width: 320px) {
      text-align: center;
    }
  }

  &__info {
    font-family: Montserrat Alternates;
  }

  &__made {
    font-family: Montserrat;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
    margin-top: 44px;
    margin-bottom: 28px;
  }
}

.pepperChilly {
  position: absolute;
  left: 35%;
  bottom: 0;

  @media screen and (max-width: 1440px) {
    display: none;
  }
}
