@import '../../assets/scss/variables.scss';

.delivery {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #d5503c;
  height: 75vh;
  min-height: 719px;

  @media screen and (max-width: 980px) {
    align-items: center;
  }

  @media screen and (max-width: 720px) {
    min-height: fit-content;
    height: fit-content;
  }
}

.width {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #d5503c;
  transition: transform 0.5s ease;
}

.active_width {
  transform: translateX(-30%);
}

.delivery_vegetables {
  display: flex;
  justify-content: flex-end;
  z-index: 9999;

  @media screen and (max-width: 1280px) {
    & img {
      width: 380px;
    }
  }

  @media screen and (max-width: 720px) {
    display: none;
  }
}

.delivery_info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 95%;
  color: #faf1e2;
  z-index: 9999;

  @media screen and (max-width: 980px) {
    align-items: center;
  }

  @media screen and (max-width: 720px) {
    margin-top: 20px;
    margin-bottom: 80px;
  }

  &__text {
    font-family: Montserrat Alternates;
    font-size: 36px;
    font-weight: 700;

    @media screen and (max-width: 1280px) {
      font-size: 32px;
    }

    @media screen and (max-width: 720px) {
      font-size: 24px;
    }
  }

  &__title {
    font-family: Montserrat Alternates;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 96px;
    margin: 0;
    transition: transform 0.5s ease;

    @media screen and (max-width: 1280px) {
      font-size: 66px;
    }

    @media screen and (max-width: 720px) {
      font-size: 48px;
    }
  }
}

.delivery_buttons {
  position: relative;
  display: flex;
  margin-top: 8px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    margin-top: 20px;
  }

  &__order {
    font-family: SF Pro Display;
    padding: 17px 24px;
    background-color: #42572c;
    border: none;
    border-radius: 8px;
    color: #faf1e2;
    margin-right: 8px;
    text-decoration: none;

    @media screen and (max-width: 720px) {
      margin-right: 0;
      margin-bottom: 8px;
      padding: 17px 30px;
    }
  }

  &__ring {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: SF Pro Display;
    padding: 17px 18px;
    background-color: #faf1e2;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    color: #42572c;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;

    @media screen and (max-width: 720px) {
      padding: 17px 22px;
    }
  }
}

.active_phone {
  transition: all 0.3s ease;
  border-bottom-right-radius: 0;
}

.list_phones {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  max-width: 186px;
  top: 100%;
  right: 0;
  height: 0;
  list-style-type: none;
  background-color: #faf1e2;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 0;
  border-radius: 8px;
  border-top-right-radius: 0;
  transition: all 0.3s ease;
  overflow: hidden;

  @media screen and (max-width: 720px) {
    margin-top: 4px;
  }

  & li {
    position: relative;
    margin-top: 4px;
    transform: translateY(-100px);
    color: #42572c;
    font-family: SF Pro Display;
    font-size: 16px;

    @media screen and (max-width: 720px) {
      margin-top: 12px;
    }
  }
}

.mobile_link {
  color: #42572c;
  font-family: SF Pro Display;
  font-size: 16px;
  text-decoration: none;
}

.first {
  @media screen and (max-width: 720px) {
    margin-top: 0 !important;
  }
}

.active_list {
  transition: all 0.3s ease;
  height: 100px;

  @media screen and (max-width: 720px) {
    height: 150px;
    z-index: 9999;
  }

  & li {
    transform: translateY(0);
  }
}
.delivery_pizza_wrapper {
  position: relative;
  width: 413px;
  height: 291px;

  @media screen and (max-width: 720px) {
    display: none;
  }
}

.pizza {
  position: relative;
  width: 456.39px;
  height: 461.5px;
  left: 35%;
  bottom: -22%;
  z-index: 9999;
  transition: transform 0.5s ease;

  @media screen and (max-width: 1280px) {
    left: 10%;
  }

  @media screen and (max-width: 980px) {
    left: 0;
  }
}

.pepper {
  position: absolute;
  right: -250px;
  bottom: 10px;
  z-index: 9990;

  @media screen and (max-width: 1280px) {
    right: -150px;
  }

  @media screen and (max-width: 980px) {
    right: -120px;
  }
}

.salatForPizza {
  position: absolute;
  right: -210px;
  bottom: 30px;
  z-index: 9990;

  @media screen and (max-width: 1280px) {
    right: -120px;
  }

  @media screen and (max-width: 980px) {
    right: -90px;
  }

  @media screen and (max-width: 720px) {
    right: -5px;
    width: 56px;
    height: 73px;
  }
}

.less {
  transform: scale(0.7) translateX(100px);
  transition: all 0.5s ease;
}

.down {
  @media screen and (max-width: 720px) {
    transition: all 0.3s ease;
    transform: translateY(70px);
  }
}

.rotate {
  transform: rotate(-75deg);
  transition: all 0.5s ease;
}

.pizzaMobile_wrapper {
  display: none;
  position: relative;
  left: -30px;
  height: 85px;
  width: 320px;
  transition: transform 0.3s ease;
  z-index: 9990;

  @media screen and (max-width: 720px) {
    display: block;
  }
}

.pizzaMobile {
  display: none;

  @media screen and (max-width: 720px) {
    position: relative;
    top: -110%;
    display: block;
    transition: transform 0.3s ease;
    z-index: 9990;
  }
}

.pepperMobile {
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    right: 0;
    top: 33%;
    z-index: 9999;
  }
}

.salatMobile {
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
    position: absolute;
    left: 0;
    top: 45%;
    z-index: 9999;
  }
}


.arrow {
  display: none;
  margin-left: 8px;
  margin-bottom: -2px;
  transform: rotate(-90deg);
  transition: transform 0.3s ease;

  @media screen and (max-width: 720px) {
    display: block;
  }
}

.active_arrow {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}